import apiHttp from '@api/apiHttp'

const state = {
  equipments: [],
  status:undefined
}


const getters = {

  /**
   * Retorna um equipamento pelo seu número de ID
   */
  getById: (state) => (id) => {
    return state.equipments.find(ele => ele.id === id)
  },

  all: (state)=>{
    return state.equipments
  },


  pivots: (state) =>{
    let pivots = []
    if (state.equipments != null) {
      state.equipments.forEach(function (equipment) {
        if(equipment.type === "Pivot")
          pivots = pivots.concat(equipment);
      })
    }
    return pivots
  },



  /**
   * Retorna todos as Parcelas de todos os equipamentos
   */
  fields: state => {
    let fields = []

    if (state.equipments != null) {
      state.equipments.forEach(function (equipment) {
        if(equipment.fields != undefined && equipment.fields.length>0)
          fields = fields.concat(equipment.fields);
      })
    }

    return fields;
  },

  /**
   * Retorna uma parcela pelo seu número de ID
   */
  getFieldById: (state, getters) => (id) => {
    return getters.fields.find(ele => ele.id === id)
  },


}


const mutations = {

  setStatus(state,value){
    state.status = value
  },

  setAll(state, equipments) {
    state.equipments = equipments
  },


  /**
   * Atualiza o valor de um equipamento ou adiciona o  novo equipamento ao storage
   * @param {*} state 
   * @param {*} equipment 
   */
  updateEquipment(state, equipment) {
    if (equipment.id != null) {
      const index = state.equipments.findIndex((e) => e.id === equipment.id);
      if (index === -1) {

        if(equipment.fields === undefined){
          equipment.fields = []
        }

        state.equipments.push(equipment);
        //  console.log("push sensor")
      } else {
        //    console.log("update sensor")
        let dataObj = state.equipments[index];
        Object.entries(equipment).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    }
  },

  /**
   * Remove um equipamento do storage
   * @param {*} state 
   * @param {*} id 
   */
  removeEquipment(state, id) {
    const index = state.equipments.findIndex((e) => e.id === id);
    if (index !== -1) {
      state.equipments.splice(index, 1);
    }
  },

  /**
   * atualiza o valor de uma parcela ou adiciona uma nova
   * @param {*} state 
   * @param {*} field 
   */
  saveField(state, field) {
    if (field.id != null) {

      //procura o equipamento a partir da parcela
      var equipment = state.equipments.find(ele => ele.id === field.equipmentId)

      //se existir o equipamento...
      if (equipment !== undefined) {

        let storageField = null;

        //se no equipamento já existir uma lista de parcelas,
        //procura a parcela que se quer atualizar
        if (equipment.fields !== undefined) {
          storageField = equipment.fields.find(ele => ele.id === field.id)
        } 

        //se for uma nova parcela, adiciona ela na lista de parcelas
        if (storageField == null) {
          equipment.fields.push(field)
        } else {
          Object.entries(field).forEach(([key, value]) => {
            storageField[key] = value;
          });
        }
      }
    }
  },


  /**
   * remove uma parcela da lista de parcelas
   * @param {*} state 
   * @param {*} id 
   */
  removeField(state, id) {
    if (state.equipments != null) {
      state.equipments.forEach(function (equipment) {
        const index = equipment.fields.findIndex((e) => e.id === id);
        if (index !== -1) {
          equipment.fields.splice(index, 1);
          return;
        }
      })
    }
  }
}


const actions = {


  loadEquipments(context) {
    context.commit('setStatus','loading' )
    context.commit('setAll', []);

    return new Promise((resolve, reject) => {
      apiHttp.get('irrig/equipment').then((data) => {
        context.commit('setAll', data);
        context.commit('setStatus','loaded' )
        resolve(data);
      }, (error) => {
        context.commit('setStatus','error' )
        reject(error);
      });
    })
  },

  removeEquipment(context, id) {
    return new Promise((resolve, reject) => {
      apiHttp.delete("irrig/equipment/" + id).then(data => {
        context.commit('removeEquipment', id)
        resolve(data)
      }, error => {
        reject(error)
      });
    });
  },

  saveEquipment(context, equipment) {
    return new Promise((resolve, reject) => {
      apiHttp.post("irrig/equipment", equipment).then(
        data => {
          context.commit("updateEquipment", data);
          resolve(data)
        },
        error => {
          reject(error)
        }
      );
    });
  },


  saveField(context, field) {
    return new Promise((resolve, reject) => {
      apiHttp.save("irrig/equipment/field/", field, field.id).then(
        data => {
          context.commit("saveField", data);
          resolve(data)
        },
        error => {
          reject(error)
        }
      );
    });
  },

  copyField(context, fieldId) {
    return new Promise((resolve, reject) => {
      apiHttp.get("irrig/equipment/copy-field/"+fieldId).then(
        data => {
          //console.log(data)
          context.commit("saveField", data);
          resolve(data)
        },
        error => {
          reject(error)
        }
      );
    });
  },


  removeField(context, id) {
    return new Promise((resolve, reject) => {
      apiHttp.delete("irrig/equipment/field/" + id).then(data => {
        context.commit('removeField', id)
        resolve(data)
      }, error => {
        reject(error)
      });
    });
  },

  finalizeField(context,param){
    return new Promise((resolve, reject) => {
      apiHttp.post("irrig/equipment/field/" + param.id+'/finalize',param.endCycle).then(data => {
        context.commit('removeField', data.id)
        resolve(data)
      }, error => {
        reject(error)
      });
    });

  }



}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
