<template>

  <div class="d-flex">
  
  <b-button v-if="!simple" class="mr-1" variant="outline-secondary" size="sm" @click="nextInterval(-1)"> <font-awesome-icon icon="backward-step" /> </b-button>

  <div v-if="custom || simple">
    <div class="data-range" v-bind:class="!dataValid ? 'warnig' : ''">
      <b-form-datepicker size="sm" boundary="window" right
      :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }"
      v-model="startDate"
      :disabled="disabled"
      label-no-date-selected="Início"
      ></b-form-datepicker>
      
      <!-- <span> ~ </span> -->
      <b-form-datepicker size="sm" right :disabled="secondDateDisabled||disabled" :min="startDate" boundary="window"
      :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }"
      v-model="endDate"
      :max="maxDate"
      label-no-date-selected="Fim"
      ></b-form-datepicker>
      
      <b-button  size="sm" :disabled="!dataValid" v-if="!autoUpdate" @click="updateValue()" variant="primary">
        <font-awesome-icon icon="check" />
      </b-button>
    </div>
  </div>

  <div v-else>
    <b-dropdown :text="dropdownText" variant="outline-secondary" right size="sm">
      <b-dropdown-item @click="selectByInterval(0,0,'Hoje')">Hoje</b-dropdown-item>
      <b-dropdown-item @click="selectByInterval(1,1,'Ontem')">Ontem</b-dropdown-item>
      <b-dropdown-item @click="selectByInterval(7,0,'Últimos 7 dias')">Últimos 7 dias</b-dropdown-item>
      <b-dropdown-item @click="selectByInterval(30,0,'Últimos 30 dias')">Últimos 30 dias</b-dropdown-item>
      <b-dropdown-item @click="custom = true">Início e Fim</b-dropdown-item>
    </b-dropdown>
  </div>
   
  <b-button v-if="!simple" class="ml-1" variant="outline-secondary" size="sm" @click="nextInterval()"> <font-awesome-icon icon="forward-step" /> </b-button>
  
</div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "data-range",
  data() {
    return {
      startDate: undefined,
      endDate: undefined,
      updateEnable: false,
      dataValid: false,
      custom:false,
      dropdownText:undefined,
      today:undefined
    };
  },

  props: {
    value: { type: Array },
    autoUpdate: { type: Boolean, default: true },
    disabled : {type:Boolean, default:false},
    maxDaysInterval:Number,
    simple:{type:Boolean, default:false}
  },

  mounted() {
    this.today = DateTime.now().toISODate();

    this.setInputsValue();

    if (!this.autoUpdate) {
      this.updateEnable = true
    }
  },

  computed: {
    secondDateDisabled() {
      return this.startDate == undefined || this.startDate == "";
    },
    maxDate(){
      if (this.maxDaysInterval === undefined)
        return undefined
      else 
        return DateTime.fromISO(this.startDate).plus({ days: this.maxDaysInterval }).toISO()
    }

  },
  methods: {


    async selectByInterval(startDiff,endDiff,legend){

      let now =  DateTime.now()
      let start = now.plus({days:-startDiff})
      let end = now.plus({days:-endDiff})
      await this.$nextTick();
      this.startDate = start.toISODate()
      await this.$nextTick();
      this.endDate = end.toISODate()
      this.dropdownText= legend

    },


    async nextInterval(dir=1){
     
      let start =  DateTime.fromISO(this.startDate)
      let end =  DateTime.fromISO(this.endDate)
      let interval = end.diff(start, 'days').days + 1;
      interval*= dir
      start = start.plus({days:interval})
      end = end.plus({days:interval})

      this.dropdownText = undefined

      await this.$nextTick();
      this.startDate = start.toISODate()
      await this.$nextTick();
      this.endDate = end.toISODate()

    },

    async setInputsValue() {
      if (this.value) {
        let a = DateTime.fromJSDate(this.value[0]);
        let b = DateTime.fromJSDate(this.value[1]);
        this.startDate = a.toISODate();
        await this.$nextTick();
        this.endDate = b.toISODate();
        await this.$nextTick();
        this.updateEnable = true

        

        if (this.dropdownText == undefined){
          let startTxt = this.$filters.ptbrdate(this.startDate)
          let endTxt = this.$filters.ptbrdate(this.endDate)
          if(this.endDate === endTxt){
            if(startTxt === this.today )
              this.dropdownText = "Hoje"
            else
              this.dropdownText = startTxt
          }
          else{
            let interval = Math.trunc(b.diff(a, 'days').days)
            if(this.endDate === this.today){
                this.dropdownText = "Últimos "+(interval)+" dias"
            }else{
              this.dropdownText = startTxt+" - "+endTxt
            }

          }
        }
      }
      
    },

    checkValid() {
      let a = DateTime.fromISO(this.startDate);
      let b = DateTime.fromISO(this.endDate);
      this.dataValid = (a.isValid == true && b.isValid == true && b >= a)
    },

    updateValue() {
      let a = DateTime.fromISO(this.startDate);
      let b = DateTime.fromISO(this.endDate);
      if (a.isValid == true && b.isValid == true && b >= a) {
        if (this.updateEnable) {
          this.$emit("input", [a.toJSDate(), b.toJSDate()]);
        }
      }
    },
  },
  watch: {
    value() {
      this.setInputsValue();
    },
    startDate() {
      if (this.autoUpdate)
        this.endDate = "";

      this.checkValid()
    },
    endDate() {
      if (this.autoUpdate)
        this.updateValue();

      this.checkValid()
    },
  },
};
</script>

<style lang="scss" scoped>
.data-range {
  display: flex !important;
  //padding: 0.05rem 0.15rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: white;
  font-size: 14px;

  width: 280px;

  .b-form-datepicker{
    border: 0;
  }


  &.warnig {
   // background-color: #f00 !important;
  }

  input {
    //min-width: 70px;
    padding: 0;
    border: none;
    background-color: inherit;

    &::-webkit-calendar-picker-indicator {
      //   display: none;
      //   -webkit-appearance: none;
      margin: 1px;
      padding: 0;
    }

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }

  button {
    border: 1px solid transparent;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0 0.2rem 0.2rem 0;
    margin: -0.05rem -0.15rem -0.05rem 0.15rem;
  }

  span {
    padding: 0.25rem 0.5rem;
  }
}
</style>
