<template>
  <b-modal no-fade scrollable ref="sensor-edit-modal" @hidden="close" size="xl">
    <template v-slot:modal-title>
      <span>
        <b-link :to="{ name: 'feedDetails', params: { feedId: feedId } }">
          Feed
        </b-link>
        > Editar Sensor
      </span>
    </template>

    <validation-observer ref="formObserver">
      <div v-if="loadStatus == 'loaded'">
        <b-row>
          <!-- tipo de equipamento de irrigação -->
          <ValidationProvider
            slim
            vid="type"
            name="Tipo"
            :rules="{ required: true }"
            v-slot="v"
          >
            <b-form-group label="Tipo" class="col-sm-4">
              <b-form-select
                :disabled="form.id !== undefined"
                :autofocus="form.id == undefined"
                v-model="form.type"
                :options="sensorTypeList"
                name="type"
                :state="$validateState(v)"
              ></b-form-select>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- ---NOME DO Sensor --- -->
          <ValidationProvider
            slim
            vid="name"
            name="Nome"
            :rules="{ required: true, min: 5, max:15 }"
            v-slot="v"
          >
            <b-form-group label="Nome" class="col-sm-4">
              <b-form-input
                :autofocus="form.id !== undefined"
                name="sensor.name"
                v-model="form.name"
                type="text"
                maxlength="15"
                placeholder="nome"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- order-->
          <ValidationProvider
            slim
            vid="order"
            name="ordem"
            :rules="{ required: true }"
            v-slot="v"
          >
            <b-form-group label="ordem" class="col-sm-4">
              <b-input-group :state="$validateState(v)">
                <b-form-input
                  name="sensor.order"
                  v-model="form.order"
                  type="number"
                  min="0"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- DESCRIÇÃO DO Sensor-->
          <ValidationProvider
            slim
            vid="description"
            name="Descrição"
            :rules="{ max: 250, min: 5 }"
            v-slot="v"
          >
            <b-form-group
              class="col-12"
              id="input-group-description"
              label="Descrição"
            >
              <b-form-textarea
                name="sensor  .description"
                v-model="form.description"
                placeholder="Descrição"
                :state="$validateState(v)"
              ></b-form-textarea>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- defaultInterval do sensor-->
          <ValidationProvider
            slim
            vid="defaultInterval"
            name="defaultInterval"
            :rules="{ required: true, minValue: 1 }"
            v-slot="v"
          >
            <b-form-group label="Default Interval" class="col-sm-6">
              <b-input-group append="s" :state="$validateState(v)">
                <b-form-input
                  name="sensor.defaultInterval"
                  v-model="form.defaultInterval"
                  type="number"
                  min="0"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- defaultPeriod DO sensor-->
          <ValidationProvider
            slim
            vid="defaultPeriod"
            name="defaultPeriod"
            :rules="{ required: true, minValue: 5 }"
            v-slot="v"
          >
            <b-form-group label="Default Period" class="col-sm-6">
              <b-input-group append="h" :state="$validateState(v)">
                <b-form-input
                  list="timeList"
                  name="sensor.defaultPeriod"
                  v-model="form.defaultPeriod"
                  type="number"
                  min="0"
                  :state="$validateState(v)"
                ></b-form-input>

                <datalist id="timeList">
                  <option value="24"> 1 dia </option>
                  <option value="48"> 2 dias </option>
                  <option value="36"> 3 dias </option>
                  <option value="168"> 7 dias </option>
                  <option value="360"> 15 dias </option>
                  <option value="720"> 1 Mês </option>
                </datalist>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
        </b-row>

        <hr />

        <component
          v-if="form.type !== undefined"
          :is="'edit-' + form.type"
          v-model="form"
        ></component>
      </div>
      <c-loading :status="loadStatus" v-else></c-loading>
    </validation-observer>

    <template v-slot:modal-footer>
      <b-button
        size="sm"
        v-if="saveStatus == 'saved' || saveStatus == 'error'"
        @click="submit()"
        variant="success"
        >Salvar</b-button
      >
      <b-button v-else-if="saveStatus == 'saving'" variant="secondary">
        <font-awesome-icon icon="spinner" spin />
      </b-button>

      <b-button
        size="sm"
        variant="outline-secondary"
        @click="close()"
      >
        Cancelar
      </b-button>
    </template>

    <!-- {{ form }} -->
  </b-modal>
</template>

<script>
import pluvioEdit from "./sensPluvio/pluvioEdit";
import waterEdit from "./sensWater/waterEdit";
import soilEdit from "./sensSoil/soilEdit";
import pivotEdit from "./sensPivot/pivotEdit";
import stationEdit from "./sensStation/stationEdit";
import wellEdit from "./sensWell/wellEdit";
import hydrometerEdit from "./sensHydro/hydrometerEdit";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},
      loadStatus: "loading",
      saveStatus: "saved",
      sensorTypeList: [
        { value: null, text: "Selecione" },
        { value: "pluviometer", text: "Pluviômetro" },
        { value: "waterLevel", text: "Nível de Água" },
        { value: "station", text: "Estação Meterológica" },
        { value: "soil", text: "Sensor de Solo" },
        { value: "pivot", text: "Sensor de Pivô" },
        { value: "well", text: "Sensor de Poço" },
        { value: "hydrometer", text: "Sensor de Hidrômetro" },
      ]
    };
  },

  props: {
    feedId: { type: String, required: true },
    sensorId: { type: String, required: false },
  },

  components: {
    "edit-pluviometer": pluvioEdit,
    "edit-waterLevel": waterEdit,
    "edit-soil": soilEdit,
    "edit-pivot": pivotEdit,
    "edit-station": stationEdit,
    "edit-well": wellEdit,
    "edit-hydrometer": hydrometerEdit,
  },

  mounted() {
    this.$refs["sensor-edit-modal"].show();
    if (this.sensorId) {
      this.loadSensorData();
    } else {
      this.loadStatus = "loaded";
    }
  },

  methods: {


    loadSensorData() {
      this.loadStatus = "loading";
      this.$http.get("/sens/sensor/" + this.sensorId).then(
        (data) => {
          this.form = data;
          this.loadStatus = "loaded";
        },
        (error) => {
          console.log("load sensor error:", error);
          this.loadStatus = "error";
        }
      );
    },

    submit() {
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          this.$bvModal.msgBoxOk("Verifique se todos os dados estão corretos", {
            title: "Erro!",
          });
          return;
        }

        this.saveStatus = "saving";
        this.$http
          .save(
            "/sens/feed/" + this.feedId + "/sensor/",
            this.form,
            this.sensorId,
            "/sens/sensor/",
          )
          .then(
            () => {
              this.saveStatus = "saved";
              this.$router.push({
                name: "feedDetails",
                params: { feedId: this.feedId },
              });
            },
            (error) => {
              console.error("save sensor error", error);
              this.saveStatus = "error";
              this.$bvModal.msgBoxOk(
                "Verifique se todos os dados estão corretos",
                { title: "Erro!" }
              );
            }
          );
      });
    },

    close() {
      this.$router.back();
    },

 
  },
  computed:{
    ...mapGetters({
      clients: "accounts/clients",
    }),
  }
};
</script>

<style></style>
