import axios from 'axios';
import appConfig from '../appConfig.js'
// import router from '@/router'

const SERVER_URL = process.env.NODE_ENV === 'production' ? '' : appConfig.URL_TESTE;
//const SERVER_URL = appConfig.URL_TESTE

const HEADERS = {
  //  headers: {
  //    'Access-Control-Allow-Origin': '*',
  //    'Content-Type': 'application/json',
  //  },
  //  mode: 'no-cors',
  // withCredentials: true,
  //  credentials: 'same-origin',
}


const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  timeout: 60000,
});

axiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');



//Captura globalmente todas as mesnagens
axiosInstance.interceptors.response.use(
  response => response,
  (error) => {
    // // check for errorHandle config
    // if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
    //     return Promise.reject(error);
    // }
    // // if has response show the error
    if (error.response) {

      //se acontecer um erro de chave inválida, redireciona para a tela de login
      // if ([422, 403].includes(error.response.status)) {
      //    router.push({ name: "login" })
      // }
     
      // if ([500].includes(error.response.status)) {
      //   alert(error.response.data.message)
      // }

      //toast.error(error.response.data.message);
      // console.log("error:", error.response.status);
      return Promise.reject(error);

    }
  }
);


export default {


  async save(url,data,id=undefined,putUrl=undefined) {
    if(id == undefined){
      return await axiosInstance.post(url, data, HEADERS).then(response => response.data)
    }else{
      let _url = url
      if (putUrl != undefined) _url = putUrl
      if(_url.slice(-1) != '/') _url+='/'
      return await axiosInstance.put(_url+id, data, HEADERS).then(response => response.data)
    }
  },

  async post(url, data) {
    return await axiosInstance.post(url, data, HEADERS).then(response => response.data)
  },
  async get(url) {
    return await axiosInstance.get(url, HEADERS).then(response => response.data)
  },
  async delete(url) {
    return await axiosInstance.delete(url, HEADERS).then(response => response.data)
  },

  async put(url,data) {
    return await axiosInstance.put(url, data,HEADERS).then(response => response.data)
  },

  setToken(token,storage=false) {
    token = "Bearer " + token
    axiosInstance.defaults.headers.common['Authorization'] = token;

    if(storage==true){
      localStorage.setItem('access_token', token);
    }
    
  },

  
  clearToken() {
    localStorage.removeItem('access_token', '');
    axiosInstance.defaults.headers.common['Authorization'] = '';
  }
}