<template>
  <div>

    <b-card bg-variant="light">
    <h5> <font-awesome-icon icon="info-circle" /> Entenda os dados  <small>ANEXO IV</small></h5>
    <ul>
      <li>
        <b>Dados de Vazão:</b> (ddmmaaaa; hhmm; t,t; xxxxxxxxxxx;)
          
        <ul>
          <li><b>ddmmaaaa:</b> Data do término do bombeamneto</li>
          <li><b>hhmm:</b> Hora e minuto do término do bombeamneto</li>
          <li><b>t,t =:</b> Leitura do horímetro em horas, com uma casa decimal (Tempo total de
            bombeamento)</li>
          <li><b>xxxxxxxx,xx</b> Leitura do medidor em m³ acumulado no período</li>
        </ul>
      </li>
      <li> <b>Nível Estático:</b> Nível do poço imediatamente antes do início do bombeamento</li>
      <li> <b>Nível Dinâmico:</b> Nível do poço imediatamente após o término do bombeamento</li>
    </ul>

    Ref.:
    <b-link href="http://www.inema.ba.gov.br/wp-content/files/22.181_DE_22_DE_JANEIRO_DE_2021_-_Implantao_de_sistema_de_medio_para_monitoramento_dos_usos_e_intervenes_em_recursos_hdricos.pdf"> PORTARIA INEMA Nº 22.181 DE 22 DE JANEIRO DE 2021 </b-link>
  </b-card>

    <table-export
      :items="serie"
      :fields="fields"
      :tableName="''"
      :perPage="50"
      :fileName="filename"
    >
  </table-export>

  </div>
</template>

<script>
import { DateTime } from "luxon";

const dateTimeFormat = function(value) {
  return DateTime.fromISO(value).toFormat('ddMMyyyy;HHmm');
};


const formatLevel = function(obj){

  if (obj != undefined){
    return dateTimeFormat(obj.dateTime)+";"+
    obj.nivel.toLocaleString('pt-br',{minimumFractionDigits:1,maximumFractionDigits:1})

  } else{
    return ""
  }



}

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      loadingXLS:false,
      fields: [
        { key: "flow", label: "Vazão"},
        { key: "static", label: "Nível Estático"},
        { key: "dinamic", label: "Nível Dinâmico"},
      ],
    };
  },
  props: {
    data: Array,
    filename:String
  },
  computed: {
    numRows() {
      if (this.serie !== undefined) return this.serie.length;
      else return 0;
    },

    serie(){
      let serie = []

      this.data.forEach((e) => {

        serie.push({
          flow:dateTimeFormat(e.end)+";"+this.$filters.localeNumber(e.horimetro)+";"+this.$filters.localeNumber(e.volume,2),
          static:formatLevel(e.estatico),
          dinamic:formatLevel(e.dinamico),
        })
      })


      return serie;
    }
  }
};
</script>

