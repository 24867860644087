<template>
  <b-row>
    <b-col lg="3">
      <vue-avatar
        :width="100"
        :height="100"
        :rotation="rotation"
        :scale="scale"
        ref="vueavatar"
        @vue-avatar-editor:image-ready="onImageReady"
      ></vue-avatar>
      {{scale}}
      <input type="range" min="1" max="3" step="0.02" v-model="scale" />

      <!-- <img ref="image" />
      <button v-on:click="saveClicked">Click</button>-->
    </b-col>

    <b-col lg="6">
      <validation-observer v-if="userEdit!= undefined" ref="formObserver">
        <b-row>
          <ValidationProvider
            slim
            vid="name"
            name="Nome"
            :rules="{ required: true,min:5 }"
            v-slot="v"
          >
            <b-form-group label="Nome" class="col-sm-12">
              <b-form-input
                name="name"
                v-model="userEdit.name"
                type="text"
                maxlength="50"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- Sobre -->
          <ValidationProvider
            slim
            vid="about"
            name="Sobre"
            :rules="{ required: false, min:4 }"
            v-slot="v"
          >
            <b-form-group label="Sobre" class="col-sm-12">
              <b-form-input
                name="about"
                v-model="userEdit.about"
                type="text"
                maxlength="30"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- USUARIO -->
          <ValidationProvider
            slim
            vid="username"
            name="Username"
            rules="required|verify_username"
            v-slot="v"
          >
            <b-form-group label="Username" class="col-sm-6">
              <b-form-input
                name="username"
                v-model="userEdit.username"
                type="text"
                maxlength="30"
                placeholder="****.****"
                trim
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- PASSWORD -->
          <ValidationProvider
            slim
            vid="password"
            name="password"
            rules="required|verify_password"
            v-slot="v"
          >
            <b-form-group label="Password" class="col-sm-6">

              <b-input-group>
              <b-form-input
                name="password"
                v-model="userEdit.password"
                :type="viewpassword?'text':'password'"
                maxlength="30"
                :state="$validateState(v)"
                trim
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="outline-secondary" @click="viewpassword=!viewpassword"> 
                  <font-awesome-icon :icon="viewpassword?'eye':'eye-slash'"/>
                </b-button>

                <b-button variant="outline-secondary" @click="randomPassword()" title="senha aleatória"> 
                  <font-awesome-icon icon="dice"/>
                </b-button>
              </b-input-group-append>

            
                
            


          </b-input-group>

              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- PHONE -->
          <ValidationProvider
            slim
            vid="phone"
            name="telefone"
            :rules="{ required: false,min:3 }"
            v-slot="v"
          >
            <b-form-group label="Telefone" class="col-sm-6">
              <b-form-input
                name="phone"
                v-model="userEdit.phone"
                type="text"
                maxlength="30"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- email -->
          <ValidationProvider slim vid="email" name="email" rules="email" v-slot="v">
            <b-form-group label="Email" class="col-sm-6">
              <b-form-input
                name="email"
                v-model="userEdit.email"
                type="text"
                maxlength="30"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- <b-form-group label="Permissões" class="col-sm-12">
            <b-form-select v-model="user.roles" :options="rolesOptions" multiple :select-size="4"></b-form-select>
          </b-form-group> -->
        </b-row>
      </validation-observer>
    </b-col>
    <b-col cols="12" class="d-flex">
      <div class="mr-auto">
        <b-button variant="primary" @click="submit()">Salvar</b-button>
        <b-button  class="ml-2" variant="secondary" @click="$emit('editcancel')">cancelar</b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { VueAvatar } from "vue-avatar-editor-improved";
import makeId from "@/utils/makeId";

export default {
  data() {
    return {
      viewpassword : false,
      avatar: null,
      hasImage: false,
      image: null,
      scale: undefined,
      rotation: undefined,
      imageReady: false,
      userEdit:undefined,
      rolesOptions: [
        { value: "IRRIG_VIEW", text: "módulo de irrigação" },
        { value: "IRRIG_ADMIN", text: "admin de irrigação" },
        { value: "ACCOUNT_ADMIN", text: "ver usuários" },
        { value: "ACCOUNT_VIEW", text: "configurar usuários" }
      ]
    };
  },
  mounted(){
    this.userEdit = JSON.parse(JSON.stringify(this.user));
  },
  methods: {

    randomPassword(){
      this.userEdit.password = makeId(8);
      this.viewpassword = true;
    },

    onImageReady() {
      this.scale = 1;
      this.rotation = 0;
      this.imageReady = true;
    },
    // saveClicked() {
    //   let avatar = this.$refs.vueavatar.getImageScaled();
    //   this.$refs.image.src = avatar.toDataURL();
    // },

    submit() {
      this.$refs.formObserver.validate().then(result => {
        if (!result) {
          return;
        }

        let userData = JSON.parse(JSON.stringify(this.userEdit));

        if (this.imageReady) {
          let avatar = this.$refs.vueavatar.getImageScaled();
          userData.avatarbase64 = avatar.toDataURL();
        }

        this.$http.save(this.url, userData, userData.id).then(
          data => {
            this.$emit("update-user", data);
            this.$emit("editcancel");
          },
          error => {
            // console.log("error:",error.response.data.message)
            // this.$refs.formObserver.setErrors(this.$validServer(error));

            const h = this.$createElement;

            let message = h("div", {}, [
              h("strong", "Um erro aconteceu"),
              h("p", error.response.data.message)
            ]);

            // let message = "Um erro aconteceu.<br>"+error.response.data.message;
            if (error.response.data.message === "username is already in use") {
              message = "Nome de usuário em uso";
            }

            
            this.$bvModal.msgBoxOk(message, {
              title: "Erro!"
            });
          }
        );
      });
    }
  },
  props: {
    user: { type: Object, require: true },
    url: { type: String, default: "account/user" }
  },
  components: {
    VueAvatar
  }
};
</script>

<style>
#fileInput {
  display: none;
}

.img-preview {
  width: 100%;
  padding: 3px;
  border: 2px solid #d2d6de;
  margin: 0 auto;
  border-radius: 50%;
}
</style>>