<template >
    <div>
        <!-- {{summary}} -->

        <div v-if="summaryStatus == 'loaded'">
          <genericChart
            v-if="summary.chartTemp!=null"
              class="generic-chart"
              title="Temperatura"
              color="#f40"
              :serie="summary.chartTemp"
              unit="ºC"
              name="Temperatura"
              height="200px"
              yminValue="dataMin"
              maxMinPoint
            />
            <genericChart
              v-if="summary.chartHumidity!=null"
              class="generic-chart"
              title="Umidade"
              color="#08f"
              :serie="summary.chartHumidity"
              unit="%"
              name="Umidade"
              height="200px"
              yminValue="dataMin"
              maxMinPoint
            />
            <genericChart
              v-if="summary.chartWind!=null"
              class="generic-chart"
              title="Vento"
              color="#4a4"
              :serie="summary.chartWind"
              unit="m/s"
              name="Vento"
              height="200px"
             
            />
            <genericChart
              v-if="summary.chartLight!=null"
              class="generic-chart"
              title="Radiação"
              color="#f91"
              :serie="summary.chartLight"
              unit="W/m²"
              type="area"
              name="Radiação"
              height="200px"
            
            />
            <genericChart
              v-if="summary.chartRain!=null"
              class="generic-chart"
              title="Chuva"
              color="#08f"
              :serie="summary.chartRain"
              unit="mm"
              type="bar"
              name="Chuva"
              height="200px"
              
            />
        </div>
        <c-loading v-else :status="summaryStatus"></c-loading>
    </div>
</template>
<script>

import genericChart from "@kl-framework/charts/genericChart";
export default {
    data() {
      return {
        summaryStatus: "loading",
        summary: {},
      };
    },

    props:{
      stationId :String
    },
    components:{
        genericChart
    },

    mounted() {
      this.loadSummary();
    },

    methods: {
      loadSummary() {
        this.$http.get("sens/sensor/" + this.stationId + "/summary").then(
          (data) => {
            this.summary = data;
            this.summaryStatus = "loaded";
          },
          (error) => {
            console.error("load water sensor error:", error);
            this.summaryStatus = "error";
          }
        );
      },
    },
}
</script>
<style lang="">
    
</style>