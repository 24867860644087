<template>
    <div class="station-widget" @click="showDetail">

        <div class="name"> {{ sensor.name }}</div>

        <div v-if="isOnline">
            <div class="value d-flex">
                <font-awesome-icon icon="temperature-half"/> 
                <div>
                    {{ $filters.round(temperature, 1) }}<small>°C</small>
                </div>
                <div class="max-min">
                    <div class="text-success">  {{ $filters.round(temperature_max, 1) }}⏶</div>
                    <div class="text-danger">  {{ $filters.round(temperature_min, 1) }}⏷</div>
                </div>
            
            </div>
            <div v-if="rain!=null" class="value"><font-awesome-icon icon="cloud-rain"/> {{ $filters.round(rain, 1) }}<small>mm</small> </div>
            <div v-if="humidity!=null" class="value"><font-awesome-icon icon="droplet"/> {{ $filters.round(humidity, 1) }}<small>%</small></div>
            <div v-if="windKm!=null" class="value"><font-awesome-icon icon="wind"/> {{ $filters.round(windKm, 1) }}<small>km/h</small></div>
        </div>
        <div class="offline" v-else>
            <font-awesome-icon icon="plug-circle-xmark"/>  Offline!
        </div>



    </div>
</template>
<script>

import station from './station'

export default {
    extends: station,
    methods: {
        showDetail() {
            this.$router.push('/sensor/' + this.sensor.id)
        }
    }
}
</script>

<style lang="scss" scoped>

$box-radius: 4px;

.station-widget {
    cursor: pointer;
    border-radius: $box-radius;
    color: #555555;
    background: rgb(255, 245, 225);
    font-size: 14px;
    // display: flex;
    padding: 5px;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.5);
    margin-bottom: 1px;
    position: relative;
    justify-content: flex-end;
    flex-direction: row;
    user-select: none;

    .name {
        font-weight: bold;
        padding-right:6px;
        font-size: 10px;
        line-height: 20px;
        border-bottom : 1px solid rgb(170, 170, 170);
        margin-bottom: 2px;
        text-align: center;
        
    }

    .value {
        // border-left: 1px solid rgb(170, 170, 170);
        padding-left: 5px;
        padding-right: 5px;
        font-weight: bold;
        color: #425b8b;
        small{
            color: #555555;  
        }

        .max-min{
            margin-right: -5px;
            padding-left: 5px;
            font-size: 10px;
            line-height: 10px;
        }
    }

    .offline{
        padding-left: 15px;
    }


}
</style>