<template>
  <b-card>
    <div>
      <h5> 
        <img> 
        <img class="mr-2" :src="require(`../assets/mapPin/${sensor.type}.png`)" :alt="sensor.type"  />

        <!-- <font-awesome-icon :icon="getIcon(sensor.type)" class="nav-icon" /> -->
          {{ sensor.name }}
      </h5>

    </div>
     <!-- {{ sensor.type }}  -->
      <div class="d-flex">
       
        <div class="ml-auto">
            <b-button
              size="sm"
              v-b-tooltip.hover
              title="Editar Especialização do feed"
              variant="primary"
              :to="{
                name: 'sensorEdit',
                params: { feedId: feedId, sensorId: sensor.id },
              }"
            >
              <font-awesome-icon icon="edit" />
            </b-button>

            <b-button
              size="sm"
              v-b-tooltip.hover
              title="Remover Especialização do feed"
              variant="danger"
              @click="$emit('remove-sensor')"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
        </div>
      </div>
            
      <div class="mt-2">
        <div class="client-list" v-for="client in clients" :key="client.client">
         
          <div>
             <b> {{ selectClientName(client.client)}}</b>
          </div>

          <b-form-group>
            <b-form-checkbox switch  v-model="client.visible" @input="updateClient(client)"> Visível </b-form-checkbox>
            <b-form-checkbox switch  v-model="client.editable" @input="updateClient(client)"> Editável </b-form-checkbox>
          </b-form-group>
            

            

            <div class="ml-auto">
              <b-button
              size="sm"
              @click="removeClient(client.client)"
              variant="outline-danger"
              >remover</b-button
              >
            </div>
        </div>

        <div class="mt-2">
          <b-input-group size="sm">
            <b-form-select v-model="form.client" :options="clientList"></b-form-select>
            <b-input-group-append>
              <b-button
              @click="addClient()"
              variant="outline-success"
              >adicionar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

      </div>
  </b-card>
</template>
<script>
export default {

    data(){
        return{
          newClientId:undefined,
          saveStatus:"saved",
          form:{},
          clients:undefined
        }
    },

    props:{
        sensor:{ type: Object, required:true},
        clientList:{type:Array, required:true},
        feedId:{type: String, required: true}
    },

    mounted(){
      // this.clients = this.sensor.clients
      this.form = {
            client:"",
            visible:true,
            editable:false
          };

      this.loadClients()
    },

    methods:{




        loadClients(){
          this.$http.get("/sens/sensor/"+this.sensor.id+"/client").then(
            (data) => {
              this.clients = data
              this.saveStatus = "saved";
            },
            (error) => {
              console.log("save client error:", error);
              this.saveStatus = "error";
            }
          );
        },

        selectClientName(client){
          let index = this.clientList.findIndex((c)=>c.value== client)
          if(index>-1){
            return this.clientList[index].text
          }else
           return client
        },

        addClient() {
          this.saveStatus = "saving";
          this.$http.post("/sens/sensor/"+this.sensor.id+"/client", this.form).then(
            (data) => {
              this.clients = data
              this.saveStatus = "saved";
            },
            (error) => {
              console.log("save client error:", error);
              this.saveStatus = "error";
            }
          );
        },

        removeClient(client) {
          this.saveStatus = "saving";
          this.$http.delete("/sens/sensor/"+this.sensor.id+"/client/"+client).then(
            (data) => {
              this.clients = data
              this.saveStatus = "saved";
            },
            (error) => {
              console.log("save client error:", error);
              this.saveStatus = "error";
            }
          );
        },

        updateClient(client) {
          this.saveStatus = "saving";
          this.$http.put("/sens/sensor/"+this.sensor.id+"/client/"+client.client,client).then(
            (data) => {
              this.clients = data
              this.saveStatus = "saved";
            },
            (error) => {
              console.log("save client error:", error);
              this.saveStatus = "error";
            }
          );
        },
    }
    
}
</script>
<style lang="scss" scoped>
    
.client-list{
 // display: flex;
  padding: 1rem 1rem 1rem ;
  border: 1px solid $border-color;
  margin-bottom: 1rem;
}

</style>