<template>
  <b-row>
    <!-- windDataStreamId-->
    <ValidationProvider
      slim
      vid="windDataStreamId"
      name="Vento Id"
      :rules="{ required: true, between:[-1,20]}"
      v-slot="v"
    >
      <b-form-group label="Vento SensorId" class="col-sm-6 col-lg-4">
        <b-input-group :state="$validateState(v)">
          <b-form-input
            name="sensor.windDataStreamId"
            v-model="editable.windDataStreamId"
            type="number"
            min="-1"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- temperatureDataStreamId-->
    <ValidationProvider
      slim
      vid="temperatureDataStreamId"
      name="Temperatura Id"
      :rules="{ required: true, between:[-1,20]}"
      v-slot="v"
    >
      <b-form-group label="Temperatura SensorId" class="col-sm-6 col-lg-4">
        <b-input-group :state="$validateState(v)">
          <b-form-input
            name="sensor.temperatureDataStreamId"
            v-model="editable.temperatureDataStreamId"
            type="number"
            min="-1"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- humidityDataStreamId-->
    <ValidationProvider
      slim
      vid="humidityDataStreamId"
      name="Umidade Id"
      :rules="{ required: true, between:[-1,20]}"
      v-slot="v"
    >
      <b-form-group label="Umidade SensorId" class="col-sm-6 col-lg-4">
        <b-input-group :state="$validateState(v)">
          <b-form-input
            name="sensor.humidityDataStreamId"
            v-model="editable.humidityDataStreamId"
            type="number"
            min="-1"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- lightDataStreamId-->
    <ValidationProvider
      slim
      vid="lightDataStreamId"
      name="Luz Id"
      :rules="{ required: true, between:[-1,20]}"
      v-slot="v"
    >
      <b-form-group label="Luz SensorId" class="col-sm-6 col-lg-4">
        <b-input-group :state="$validateState(v)">
          <b-form-input
            name="sensor.lightDataStreamId"
            v-model="editable.lightDataStreamId"
            type="number"
            min="-1"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- pluviometerDataStreamId-->
    <ValidationProvider
      slim
      vid="pluviometerDataStreamId"
      name="Pluvio Id"
      :rules="{ required: true, between:[-1,20]}"
      v-slot="v"
    >
      <b-form-group label="Pluvio SensorId" class="col-sm-6 col-lg-4">
        <b-input-group :state="$validateState(v)">
          <b-form-input
            name="sensor.pluviometerDataStreamId"
            v-model="editable.pluviometerDataStreamId"
            type="number"
            min="-1"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

   
  </b-row>
</template>

<script>
export default {
  data(){
    return{
      editable:undefined
    }
  },
  props: {
    value: { type: Object, required: true },
  },

  beforeMount(){
    this.editable = JSON.parse(JSON.stringify(this.value))

    if(!this.editable.windDataStreamId){
      this.editable.windDataStreamId = 1;
    }

    if(!this.editable.temperatureDataStreamId){
      this.editable.temperatureDataStreamId = 2;
    }

    if(!this.editable.humidityDataStreamId){
      this.editable.humidityDataStreamId = 3;
    }

    if(!this.editable.lightDataStreamId){
      this.editable.lightDataStreamId = 4;
    }

    if(!this.editable.pluviometerDataStreamId){
      this.editable.pluviometerDataStreamId = 5;
    }
  },
  watch:{
    editable: {
      deep: true,
      handler(v) {
       this.$emit("input",v)
      },
    },
  },
};
</script>

<style></style>
